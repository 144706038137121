import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/layout/Seo"
import Services from "../components/layout/Services"

const Section = styled.section`
  max-width: 1264px;
  padding: 32px 16px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 64px 32px;
  }
`

const Intro = styled.div`
  background: ${({ theme }) => theme.blue};
  border-radius: 32px;
  max-width: 1064px;
  margin: 0 auto 64px;
  overflow: hidden;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const Box = styled.div`
  padding: 32px 24px 24px;

  h3 {
    color: ${({ theme }) => theme.white};
    margin: 0;
    text-transform: capitalize;
  }

  p {
    color: ${({ theme }) => theme.white};
    font-weight: 700;
    margin: 32px 0 0;
  }

  @media (min-width: 768px) {
    padding: 32px 64px 32px 32px;

    h3 {
      font-size: 2.625rem;
      line-height: 1.62em;
    }

    p {
      margin-top: 64px;
    }
  }
`

export default function ServicesTemplate({ data }) {
  const {
    mdx: {
      frontmatter: {
        seoTitle,
        seoDescription,
        coverTitle,
        coverDescription,
        cover,
      },
      body,
    },
  } = data

  return (
    <Layout path='/services' servicesPage>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={cover.childImageSharp.ogImage}
      />
      <Section>
        <Intro>
          <GatsbyImage
            image={getImage(cover)}
            alt={coverTitle}
            placeholder="blurred"
            css={css`
              height: 85.8vw;
              max-height: 460px;
              min-height: 321.8px;

              @media (min-width: 768px) {
                min-width: 47%;
                max-width: 500px;
                order: 1;
              }
            `}
          />
          <Box>
            <h3>{coverTitle}</h3>
            {coverDescription && <p>{coverDescription}</p>}
          </Box>
        </Intro>
        <MDXRenderer>{body}</MDXRenderer>
      </Section>
      <Services />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        seoTitle
        seoDescription
        coverTitle
        coverDescription
        cover {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 80)
            ogImage: resize(
              fit: COVER
              height: 630
              width: 1200
              quality: 90
              toFormat: JPG
            ) {
              src
              width
              height
            }
          }
        }
      }
      body
    }
  }
`
